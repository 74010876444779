<template>
  <div class="personage">
    <div class="header">
      <img :src="userInfo.headpic"/>
      <div class="certificationHeader">
        <div  class="certification">
          <h3>{{ userInfo.name }}</h3><div v-show="userInfo.rentAuth">已认证</div>
        </div>
        <p ref='refname'>{{ userInfo.username }}</p>
      </div>
      <div class="hint" @click="myMsg">
        <div class="dot" v-show="myMsgData.isNotRead"></div>
<!--        <van-badge  :content="9">-->
<!--          <div class="child" />-->
<!--        </van-badge>-->
        <img src='../images/hot.png'>
      </div>
      <!--      消息列表-->
      <van-popup v-model="msgShow" position="right" style="height: 100%;width: 100%" class="msgPopUp">
        <div class="noMsg" v-show="noMsgData">
          <img src="../images/noMsg.png"/>
          <p>暂无更多消息</p>
        </div>
        <div class="msgList">
          <div class="msgDetails" v-for="v in msgListData" :key="v.time">
            <img src="../images/msgIcon.png"/>
            <div class="msgDetailsTxt">
              <div>
                <h3>系统消息</h3>
                <p>{{ v.msg }}</p>
              </div>
              <div>{{ v.createTime | dataFormat }}</div>
            </div>
          </div>
          <div @click="closeMessages" class="msgPopUpShowStyle">
            <img src="../images/backPre.png" alt="">
          </div>
        </div>
<!--        <div class="iconfont icon-cuowuguanbiquxiao-xianxingyuankuang closeTheBack" @click="closeTheBack"></div>-->
      </van-popup>
    </div>
    <div class="aboutPersonage" >
      <div class="myOrder">
        <div class="myOrderTxt">
          <div>我的订单</div>
          <div @click="all">全部<span class="iconfont icon-xiayiyeqianjinchakangengduo closeTheBack" style="font-size: 0.3rem"></span></div>
        </div>
        <div class="myOrderImg">
          <div class="imgList"   @click="deliveryOf">
            <van-badge :content="myMsgData.tobeDelivered" color="#FB884F">
              <div class="child"> <div>
                <img src="../images/thedeliveryof.png"/>
              </div>
                <p>发货中</p></div>
            </van-badge>
          </div>
          <div class="imgList" @click="inTheLease">
            <van-badge :content="myMsgData.rent" color="#FB884F">
              <div class="child"> <div>
                <img src="../images/Inthelease.png" style="width: 0.56rem"/>
              </div>
                <p>租赁中</p></div>
            </van-badge>
          </div>
          <div class="imgList" @click="inTheBack">
            <van-badge :content="myMsgData.returning" color="#FB884F">
              <div class="child"> <div>
                <img src="../images/willStayIn.png"/>
              </div>
                <p>归还中</p></div>
            </van-badge>
          </div>
          <div class="imgList" @click="myOrder">
            <van-badge :content="myMsgData.beEvaluated" color="#FB884F">
              <div class="child"> <div>
                <img src="../images/toEvaluate.png"/>
              </div>
                <p>待评价</p></div>
            </van-badge>
          </div>
        </div>
      </div>
      <div class="personalCenter">
        <div class="personalCenterTxt">常用工具</div>
        <div class="personalCenterList">
          <div class="serviceList" v-for="(v,index) in personalCenter" :key="v.img" @click="callWe(index)">
            <div><img :src="v.img"/></div>
            <p>{{ v.txt }}</p>
          </div>
        </div>

      </div>
    </div>
    <van-dialog v-model="dialog" show-cancel-button confirm-button-text="呼叫" confirm-button-color="#3366FF"
                cancel-button-color="#3366FF" @confirm="ok">
      <div class="phoneNumber">19150324669</div>
    </van-dialog>

    <van-overlay :show="show">
      <div class="wrapper">
        <div class="weixin" v-show="weiXinShow">
          <div class="iconfont icon-cuowuguanbiquxiao-xianxingyuankuang weiXinDown" @click="weiXinDown"></div>
          <img src="../images/weixincode.png"/>
          <h5>·客服微信·</h5>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import deliveryAddress from './components/deliveryAddress'
import api from "../../utils/api";
import leaseApi from "../../utils/leaseApi";

export default {
  name: "my",
  components: {
    deliveryAddress
  },
  data() {
    return {
      show: false,
      msgShow: false,//个人消息
      noMsgData: false,//吴数据状态
      dialog: false,//人工电话弹窗
      weiXinShow: false,//微信弹窗
      addRssShow: false,
      myMsgData:{},
      //用户信息
      userInfo : {},
      personalCenter: [{
        img: require('../images/freeandcertification.png'),
        txt: '免押认证'
      }, {
        img: require('../images/Forrenewal.png'),
        txt: '续租'
      }, {
        img: require('../images/withoutAny.png'),
        txt: '退租'
      }, {
        img: require('../images/buyout.png'),
        txt: '买断设备'
      }, {
        img: require('../images/invoice.png'),
        txt: '发票助手'
      }, {
        img: require('../images/personalinformation.png'),
        txt: '收货地址'
      },  {
        img: require('../images/WeChatcustomerservice.png'),
        txt: '微信客服'
      }],
      msgListData: []
    }
  },
  methods: {
    myOrder() {
      this.$router.push({name: "myOrder"})
    },
    all() {
      this.$router.push({name: "order",params:{active:'all'}})
    },
    deliveryOf() {
      this.$router.push({name: "order",params:{active:'deliveryOf'}})
    },
    inTheLease(){
      this.$router.push({name: "order",params:{active:'inTheLease'}})
      setTimeout(function (){
        location.reload();
      },2000)
    },
    inTheBack(){
      this.$router.push({name: "order",params:{active:'inTheBack'}})
    },
    myMsg() {
      this.msgShow = true
      this.getUserMsg()
    },
    closeMessages(){
      this.msgShow = false
    },
    // 人工微信弹窗
    callWe(index) {
      if (index == 7) {
        this.dialog = true
      }
      if (index == 6) {
        this.weiXinShow = true
        this.show = true
      }
      if (index == 5) {
        this.$store.commit('setAddressShow',null)
        this.$router.push({name: "deliveryAddress"})
      }if(index==4){
        this.$router.push({name: "InvoiceToApplyFor"})
      }if(index==0){
        this.$router.push({name: "freeAndCertification"})
      }if(index==1||index==2){
        this.$router.push({name: "order"})
      }
    },
    ok() {
      window.location.href = 'tel://' + '19150324669'
    },
    weiXinDown() {
      this.weiXinShow = false
      this.show = false
    },
    async fetchData(){
      // await api.getUserInfo();
      const {data} = await api.getFalseUserInfo();
      this.userInfo.username=data.username.substring(0,3)+"****"+data.username.substring(8)
      this.userInfo.headpic=data.headpic
      this.userInfo.name=data.name
      this.userInfo.rentAuth=data.rentAuth
    },
    async getMyData(){
      const {data}=await leaseApi.getPersonalData()
      // console.log(data)
      this.myMsgData=data
    },
    // 获取用户消息
    async getUserMsg(){
      const {data}=await leaseApi.getUserMsg();
      this.msgListData=data
    },
  },
  async created() {
    await this.fetchData()
    await this.getMyData()
  }
}
</script>

<style lang="less" scoped>
.personage {
  background: url("../images/myBackground.png") no-repeat;
  background-size: 100%;
  height: 100vh;
  position: relative;

  .header {
    height: 6.82rem;
    display: flex;
    position: relative;
    padding-left: 0.67rem;
    padding-top: 0.99rem;

    > img {
      width: 1.94rem;
      height: 1.94rem;
      border-radius: 50%;
    }
    .certificationHeader {
      padding-top: 0.33rem;
      margin-left: 0.44rem;
      > p {
        font-size: 0.32rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        margin-top: 0.13rem;
      }
    }

    .hint {
      position: absolute;
      top: 0.39rem;
      right: 0.51rem;
      img{
        width: 0.67rem;
      }
      //.msg {
      //  font-size: 0.61rem;
      //  color: #FFFFFF;
      //}
      //
      .dot {
        position: absolute;
        width: 0.19rem;
        height: 0.19rem;
        background: #FF0000;
        border: 0px solid #FFFFFF;
        border-radius: 50%;
        top: 5%;
        right: -0.06rem;
      }
    }

  }

  .aboutPersonage {
    width: 9.2rem;
    margin-left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: 20%;

    .myOrder {
      background: #FFFFFF;
      border-radius: 0.21rem;
      padding: 0.44rem;

      .myOrderTxt {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.43rem;
        div:nth-child(1) {
          font-size: 0.44rem;
          font-family: xiaocheng;
          font-weight: bold;
          color: #333333;
        }

        div:nth-child(2) {
          font-size: 0.33rem;
          font-family: xiaocheng;
          font-weight: 400;
          color: #999999;
        }
      }

      .myOrderImg {
        display: flex;
        justify-content: space-between;
        text-align: center;

        .imgList {
          position: relative;

          img {
            width: 0.67rem;
          }

          p {
            font-size: 0.37rem;
            font-family: xiaocheng;
            font-weight: 400;
            color: #333333;
          }

          .orderNumber {
            position: absolute;
            top: -0.03rem;
            right: 0.02667rem;
            width: 0.4rem;
            height: 0.4rem;
            background: #FB884F;
            border: 0 solid #FFFFFF;
            border-radius: 50%;
            font-size: 0.4rem;
            font-family: xiaocheng;
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
            line-height: 0.4rem;
          }
        }
      }
    }

    .personalCenter {
      background: #FFFFFF;
      border-radius: 0.28rem;
      margin-top: 0.21rem;
      padding: 0.44rem;

      .personalCenterTxt {
        font-size: 0.44rem;
        font-family: xiaocheng;
        font-weight: bold;
        color: #333333;
        margin-bottom: 0.43rem;
      }

      .personalCenterList {
        display: flex;
        flex-wrap: wrap;

        .serviceList {
          text-align: center;
          width: 25%;

          img {
            width: 0.93rem;
          }

          p {
            font-size: 0.36rem;
            font-family: xiaocheng;
            font-weight: 400;
            margin-bottom: 0.56rem;
          }
        }
      }
    }
  }
}

.noMsg {
  position: relative;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);

  img {
    width: 6.94rem;
  }

  p {
    font-size: 0.36rem;
    font-family: xiaocheng;
    font-weight: 400;
    color: #666666;
    margin-top: 0.64rem;
  }
}

.msgPopUp {
  overflow-y: initial;
  padding-bottom: 1.5rem;
  .closeTheBack {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 0.5rem;
    color: #666666;
  }
}

.msgList {
  height: 100%;
  padding: 1rem 0.67rem 0rem 0.67rem;
  overflow: scroll;
  .msgDetails {
    display: flex;
    margin-bottom: 0.44rem;

    img {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.33rem;
    }

    .msgDetailsTxt {
      flex: 1;
      display: flex;
      border-bottom: 0.01rem solid #EFEFEF;
      padding-bottom: 0.44rem;
      justify-content: space-between;

      > div:nth-child(1) {
        flex: 1;
        h3 {
          font-size: 0.42rem;
          font-family: xiaocheng;
          font-weight: 400;
          color: #333333;
          margin-bottom: 0.32rem;
        }

        p {
          font-size: 0.33rem;
          font-family: xiaocheng;
          font-weight: 400;
          color: #666666;
        }
      }

      > div:nth-child(2) {
        font-size: 0.31rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}

.van-overlay {
  height: 100%;
}

.phoneNumber {
  font-size: 0.58rem;
  font-family: xiaocheng;
  font-weight: bold;
  color: #333333;
  text-align: center;
  padding: 0.88rem 0rem;
}

.weixin {
  position: fixed;
  background: #FFFFFF;
  text-align: center;
  padding: 1rem 1.24rem;
  top: 3rem;
  border-radius: 0.28rem;
  border: 0.07rem solid #FB884F;


  h4 {
    font-size: 0.72rem;
    font-family: xiaocheng;
    font-weight: 500;
    color: #000000;
    margin-bottom: 0.44rem;
  }

  img {
    width: 5.89rem;
    margin-bottom: 0.16rem;
  }

  h5 {
    font-size: 0.64rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
  }

  p {
    font-size: 0.28rem;
    font-family: xiaocheng;
    font-weight: 400;
    color: #999999;
  }

  .weiXinDown {
    position: absolute;
    left: calc(100% - 0.44rem);
    //left: 4.3rem;
    font-size: 0.88rem;
    color: #999;
    top: -1rem;
  }
}

.addRssDialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #F6F6F6;
  z-index: 99;
  .addRssReturn {
    font-size: 0.5rem;
    position: fixed;
    top: 0px;
    z-index: 100;
  }
}
.certification{
  display:flex;
  align-items: center;
  h3{
    font-size: 0.4rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #000000;
  }
  div{
    width: 1.84rem;
    height: 0.76rem;
    background: url("../images/certification.png") no-repeat;
    background-size: 100%;
    color: #FFFFFF;
    font-size: 0.31rem;
    font-family: xiaocheng;
    font-weight: 400;
    color: #FFFFFF;
    line-height:0.76rem ;
    text-indent: 0.66rem;
    align-self: center;
    margin-left: 0.28rem;
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
/deep/.van-badge--fixed{
  top: 0.1rem;
  right: 0.28rem;
}
.msgPopUpShowStyle {
  position: fixed;
  left: 0.1rem;
  top: 82%;
  width: 1.083rem;
  height: 1.083rem;
  text-align: center;
  border-radius: 50%;
  line-height: 70px;
  img{
    width: 1.29rem;
    height:1.29rem;
  }
}
</style>
